/* ==========================================================================
   #CONTENT
   ========================================================================== */

.main-content {
    margin-bottom: $base-spacing-unit*2;
}

.content {
    @include font-size(18px, 24px);
    margin-bottom: $base-spacing-unit*3;


    h2, h3, h4, h5, h6 {
        @include font-size(20px);
    }

    p {
        color: #4A4A4A;
    }

    h2, h3 {
        margin-bottom: 2px;
    }

    * + h2 { margin-top: $base-spacing-unit*2.5; }
    * + h3 { margin-top: $base-spacing-unit*2.5; }
    * + h4 { margin-top: $base-spacing-unit*2.5; }
    * + h5 { margin-top: $base-spacing-unit*2.5; }
    * + h6 { margin-top: $base-spacing-unit*2.5; }

    ul, ol {
        margin-top: 0;
        margin-bottom: 20px;
        margin-left: 20px;
        color: #4A4A4A;
    }
    p {
        margin: 0;
    }
    p + p {
        margin-top: $base-spacing-unit;
    }
    p + ul,
    p + ol {
        margin-top: $base-spacing-unit;
    }
    ul + p,
    ol + p {
        margin-top: 0;
    }

}


hr {
    margin-top: $base-spacing-unit;
    border: 0;
    height: 1px;
    background-color: darken($color-ui, 10%);

}
