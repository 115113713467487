/* ==========================================================================
   #NOTES
   ========================================================================== */


.notes {
    margin-top: $base-spacing-unit;
    margin-bottom: $base-spacing-unit;
    background-color: #f0f0f0;
    padding: $base-spacing-unit;

    @include font-size(16px, 19px);

    h5 {
        @include font-size(16px, 19px);
    }



}
