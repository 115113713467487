/* ==========================================================================
   #SLIDER

   ========================================================================== */

.home-slider{
    height: 80vh;
    max-height: 100vw;

}
.slider-container{
    height: 100%;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    background-color: $color-grey;
}

.slider__quote{
    .site-title{
        display: block;
        color: $color-brand;
        margin-bottom: 20px;
        @include font-size(25px, 20px);
        @include mq($from: tablet) {
            @include font-size(40px, 35px);
        }

    }
    a{
        color: white;
        &:hover{
            color: $color-brand;
            cursor: pointer;
        }
    }
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;
    position: relative;
    top: 40%;
    @include font-size(38px, 40px);
    font-weight: 600;
    @include mq($from: tablet){
        @include font-size(60px, 50px);
        max-width: 400px;
        position: relative;
        left: 10%;
        top: 20%;
    }
}