/* ==========================================================================
   #GRID
   ========================================================================== */


@lost flexbox flex;
@lost gutter 0px;
@lost cycle none;

// Default


.grid {
    lost-flex-container: row;


}

.grid-steps{
    a{
        color: #000;
        &:hover{
            color: $color-brand;
            img{
                transform: scale(0.95);
                transition-duration: 0.3s;
            }
        }
        width: 100%;
        img{
            width: 100%;
            margin-bottom: 10px;
        }
    }

}

.no-equal-height {
    align-items: flex-start;
}


.grid__item {
    margin-bottom: $default-gutter*3;

}


.one-half {

    lost-column: 1/1;

    @include mq($from: phone) {
        lost-column: 1/2 2 20px;

    }
}



.one-third {
    lost-column: 1/1;

    @include mq($from: tablet) {
        lost-column: 1/2 2 20px;

    }

    @include mq($from: desktop) {
        lost-column: 1/3 3 20px;

    }
}


.two-third {
    lost-column: 1/1;
    display: flex;
    @include mq($from: tablet) {
        lost-column: 1/2 2 20px;
    }

    @include mq($from: desktop) {
        lost-column: 2/3 3 20px;

    }
}


.one-eight {
    lost-column: 1/2;


    @include mq($from: phone-small) {
        lost-column: 1/3 3 20px;
    }

    @include mq($from: phone) {
        lost-column: 1/4 4 20px;
    }

    @include mq($from: tablet) {
        lost-column: 1/6 6 20px;
    }

    @include mq($from: desktop) {
        lost-column: 1/8 8 20px;
    }
}


.one-quarter {
    lost-column: 1/1;
    display: flex;

    @include mq($from: tablet) {
        lost-column: 1/3 3 20px ;
    }

    @include mq($from: desktop) {
        lost-column: 1/4 4 20px ;
    }

}


.masthead--flex {

    .one-third {
        lost-column: 1/1;
        display: flex;
        @include mq($from: tablet) {
            lost-column: 1/3 null 0;
        }
    }

    .two-third {
        lost-column: 1/1;
        display: flex;
        @include mq($from: tablet) {
            lost-column: 2/3 2 0;
        }
    }

}