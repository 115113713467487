/* ==========================================================================
   #BANDS
   ========================================================================== */

.band {
    padding-top: $base-spacing-unit;
    padding-bottom: $base-spacing-unit;
    margin-bottom: $base-spacing-unit*2;
}


.band__light {
    background-color: darken($color-ui, 3%);
}
.band__dark {
    background-color: black;
}